export default {
    seo: {
        title: 'Bank Details | Bank 131',
        description: 'Our bank details'
    },
    title: 'Bank Details',
    content: {
            rectangle: [
                {
                    title: 'FULL NAME',
                    description: 'Limited liability company Bank 131'
                },
                {
                    title: 'ABBREVIATED NAME',
                    description: 'LLC Bank 131'
                },
                {
                    title: 'FULL BRAND NAME IN ENGLISH',
                    description: 'Limited liability company "Bank 131"'
                },
                {
                    title: 'ABBREVIATED NAME IN ENGLISH',
                    description: 'LLC "Bank 131"'
                },
                {
                    title: 'LEGAL ADDRESS',
                    description: '420012, 38 Nekrasova street, Kazan, Republic of Tatarstan, Russian Federation'
                },
                {
                    title: 'MAILING ADDRESS',
                    description: '420012, 38 Nekrasova street, Kazan, Republic of Tatarstan, Russian Federation'
                },
                {
                    title: 'THE ACTUAL ADDRESS',
                    description: '420012, 38 Nekrasova street, Kazan, Republic of Tatarstan, Russian Federation'
                },
                {
                    title: 'LICENSE REGISTRATION NUMBER',
                    description: '3538'
                },
                {
                    title: 'LICENSE',
                    description: 'The basic license for banking operations in rubles and foreign currency (with the right to attract deposits from individuals) and for banking operations with precious metals No. 3538 dated 05/16/2024.'
                },
                {
                    title: 'AUTHORIZED CAPITAL OF THE BANK',
                    description: '340 000 000 rubles'
                },
                {
                    title: 'PARTICIPATION IN THE MANDATORY DEPOSIT INSURANCE SYSTEM',
                    description: 'Not'
                },
                {
                    title: 'TIN / RRC',
                    description: '1655415696/165501001'
                },
                {
                    title: 'STATE REGISTRATION NUMBER',
                    description: '1191690025746'
                },
                {
                    title: 'SRN ASSIGNMENT DATE',
                    description: 'March 21, 2019'
                },
                {
                    title: 'CORRESPONDENT ACCOUNT',
                    description: '30101810822029205131 open in the National Bank of the Republic of Tatarstan of ' +
                        'the Volga-Vyatka Main Directorate of the Central Bank of the Russian Federation'
                },
                {
                    title: 'BIC',
                    description: '049205131'
                },
                {
                    title: 'NATIONAL CLASSIFIERS IN STATISTICAL REGISTER',
                    description: 'OKPO 36795400 OKATO 92401367000 <br/>' +
                        'OKTMO 92701000001 OKOGU 4210014<br/>' +
                        'OKFS 16 OKOPF 12300<br/>' +
                        'OKVED 64.19 Other monetary intermediation'
                },
                {
                    title: 'OFFICIAL BANK WEBSITE',
                    description: 'www.131.ru'
                },
                {
                    title: 'E-MAIL',
                    description: 'info@131.ru'
                },
                {
                    title: 'TELEPHONE',
                    description: '8 800 100 13 10'
                },
                {
                    title: 'OPERATING HOURS',
                    description: 'Monday - Friday: from 09:00 to 18:00 (lunch from 13:00 to 14:00, Moscow time);<br/>' +
                        'Saturday - Sunday: days off (non-working) days.'
                },
            ],
            description: 'All information posted on the website is provided for general information only and is not a public offer.<br/>' +
                '<br/>' +
                'Person responsible for the completeness, accuracy and relevance of the information published on the Web site: Artur Kropanev, press@131.ru<br/>' +
                '<br/>' +
                'Supervision of the activities of Bank 131 LLC, reg. No. 3538: credit institution (Bank 131 LLC) activities supervision is carried out by the Bank of Russia Current Banking Supervision Service.<br/>' +
                '<br/>' +
                'Central Bank of the Russian Federation contact details: 107016, Moscow, st. Neglinnaya, 12, tel. 8-800-250-4072, 8-495-771-9100.<br/>' +
                '<br/>' +
                'Compliance supervision of the Russian Federation legislation in the field of protection of the financial services consumers rights is carried out by the Consumer Rights Protection and the Bank of Russia Financial Services Availability Service.<br/>' +
                '<br/>' +
                'A complaint about Russian Federation legislation’s violation made by the credit institution’s actions (inactions), as well as about the rights and interests of individuals or legal entities protected by law, can be sent to the Bank of Russia for consideration through an Internet reception (www.cbr.ru/Internet reception)<br/>' +
                '<br/>' +
                'All information posted on this website is provided for general information only and is not a public offer. The information that is currently not available will appear soon and we will add it to the site.'
    }
}